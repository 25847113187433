<template>
  <div id="loginContainer">
    <el-dialog :visible="loginDialog" width="30%" :before-close="handleCloseDialog">
      <div class="dialogBox">
        <div class="dialogBox_title boxS">
          <div class="dialogBox_close boxC" @click="onCloseLoginModal">
            <span class="iconfont icon-close"></span>
          </div>
        </div>
        <div class="dialogBox_content">
          <div class="title1 boxC" v-if="type == 2">{{ $t("login.title1") }}</div>
          <div class="title2 boxC">
            {{ type == 1 ? $t("login.loginTypeTitle1") : $t("login.loginTypeTitle2") }}
          </div>
          <div class="contentBox boxC" v-if="type == 1">
            <div class="codeBox boxC" id="codeBox">
              <!-- <img :src="qrcodeUrl" /> -->
            </div>
          </div>
          <div class="contentBox contentBox_input" v-if="type == 2">
            <div class="inputBox boxC">
              <el-input
                v-model="phone"
                :placeholder="$t('public.placeholderTextByPhone')"
                :maxlength="11"
                @input="onPhoneChange"
              ></el-input>
            </div>
            <!-- <div class="inputBox inputBox_imgCode box">
              <el-input
                type="text"
                v-model="imgCode"
                :placeholder="$t('login.placeholderTextByImgCode')"
                class="imgCodeInput"
                @input="onImgCodeChange"
              ></el-input>
              <img :src="imgCodeUrl" alt="" @click="onUpdateImgCodeUrl" />
              <span class="updateImgCodeUrlBtn boxC" @click="onUpdateImgCodeUrl">{{
                $t("login.updateImageCodeText")
              }}</span>
            </div> -->
            <div class="inputBox boxC">
              <el-input
                type="text"
                v-model="smsCode"
                :maxlength="6"
                :placeholder="$t('login.placeholderTextBySmsCode')"
                @input="onSmsCodeChange"
              ></el-input>
              <el-button
                :style="`opacity:${
                  isCanGetSmsCode ? 1 : 0.5
                };margin-left: 10px;color:#fff;background-color:${
                  schoolConfigInfo.colors || '#a60002'
                };`"
                @click="onGetSmsCode"
                :disabled="!isCanGetSmsCode"
                v-loading="getSmsCodeBtnLoading"
                >{{
                  isCanGetSmsCode
                    ? $t("login.getSmsCodeText")
                    : smsSeconds + $t("login.secondText")
                }}</el-button
              >
            </div>
            <div class="error_tip box">{{ errorMessage }}</div>
            <div
              :class="canLogin ? 'loginBtn boxC' : 'loginBtn loginBtnDisabled boxC'"
              @click="onLogin"
              :style="
                canLogin ? 'background-color:' + schoolConfigInfo.colors || '#a60002' : ''
              "
              v-loading="loginBtnLoading"
            >
              {{ $t("login.loginBtn") }}
            </div>
          </div>
          <div class="bottom_btns boxC">
            <div class="bottom_btn bottom_btn_change boxC" @click="onChangeType">
              {{ type == 1 ? $t("login.loginTypeText1") : $t("login.loginTypeText2") }}
            </div>
            <div class="bottom_btn boxC" @click="onCancelLogin">
              {{ $t("login.cancelLoginBtn") }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible="registerDialog" width="30%">
      <div class="dialogBox">
        <div class="dialogBox_title boxS">
          <div class="dialogBox_close boxC" @click="onCloseRegisterModal">
            <span class="iconfont icon-close"></span>
          </div>
        </div>
        <div class="dialogBox_content" style="margin-top: 0">
          <div class="title1 boxC">{{ $t("login.inputBindPhoneText") }}</div>
          <div class="contentBox contentBox_input">
            <div class="inputBox boxC">
              <el-input
                v-model="registerPhoneNumber"
                :placeholder="$t('public.placeholderTextByPhone')"
                :maxlength="11"
                @input="onRegisterPhoneNumberInput"
              ></el-input>
            </div>
            <div class="inputBox boxC">
              <el-input
                type="text"
                v-model="registerSmsCode"
                :maxlength="6"
                :placeholder="$t('login.placeholderTextBySmsCode')"
                @input="onRegisterSmsCodeChange"
              ></el-input>
              <el-button
                :style="`opacity:${
                  isCanGetRegisterSmsCode ? 1 : 0.5
                };margin-left: 10px;color:#fff;background-color:${
                  schoolConfigInfo.colors || '#a60002'
                };`"
                @click="onGetRegisterSmsCode"
                :disabled="!isCanGetRegisterSmsCode"
                v-loading="getRegisterSmsCodeBtnLoading"
                >{{
                  isCanGetRegisterSmsCode
                    ? $t("login.getSmsCodeText")
                    : registerSmsSeconds + $t("login.secondText")
                }}</el-button
              >
            </div>
            <div
              :class="canRegister ? 'loginBtn boxC' : 'loginBtn loginBtnDisabled boxC'"
              @click="onRegister"
              v-loading="registerBtnLoading"
              :style="
                canRegister
                  ? 'background-color:' + schoolConfigInfo.colors || '#a60002'
                  : ''
              "
            >
              {{ $t("login.loginBtn") }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { validPhone } from "@/utils/validate";
import {
  login,
  scanLogin,
  createWxUserByScan,
  getLoginSmsCode,
  getRegisterSmsCode,
} from "@/api/public";
export default {
  name: "LoginModal",
  data() {
    return {
      type: 2,
      qrcodeUrl: "",
      qrcodeTimer: null,
      loopCount: 0,
      phone: "",
      imgCode: "123",
      imgCodeUrl: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
      smsCode: "",
      smsTimer: null,
      smsSeconds: 60,
      isCanGetSmsCode: true,
      errorMessage: "",
      canLogin: false,
      loginBtnLoading: false,
      getSmsCodeBtnLoading: false,
      phoneValidate: false,
      imgCodeValidate: true,
      smsCodeValidate: false,
      registerDialog: false,
      registerPhoneNumber: "",
      registerSmsCode: "",
      registerSmsTimer: null,
      registerSmsSeconds: 60,
      isCanGetRegisterSmsCode: true,
      canRegister: false,
      registerPhoneValidate: false,
      registerSmsCodeValidate: false,
      openid: "",
      unionid: "",
      registerBtnLoading: false,
      getRegisterSmsCodeBtnLoading: false,
    };
  },
  created() {
    if (this.getUrlParam("code") && !sessionStorage.getItem("token")) {
      scanLogin({ code: this.getUrlParam("code") }).then((res) => {
        if (res.status == 99) {
          this.openid = res.data.openid;
          this.unionid = res.data.unionid;
          this.registerDialog = true;
          this.$store.dispatch("public/changeIsShowLoginModal", false);
        } else {
          sessionStorage.setItem("token", res.data.token);
          sessionStorage.setItem("userInfo", JSON.stringify(res.data.user.user));
          sessionStorage.setItem("schoolId", res.data.user.user.schoolId);
          sessionStorage.setItem("requestSchoolId", res.data.user.user.schoolId);
          this.$store.dispatch("public/changeIsShowLoginModal", false).then((res) => {
            console.log("isCloseLoginModalSuccess");
          });
          window.location.reload();
        }
      });
    }
  },
  mounted() {
    // this.qrcodeTimer = setInterval(() => {
    //   this.loopCount++;
    //   this.onGetQRCodeUrl();
    // }, 3000);
  },
  computed: {
    loginDialog() {
      console.log(this.$store.getters.isShowLoginModal);
      return this.$store.getters.isShowLoginModal;
    },
    schoolConfigInfo() {
      return this.$store.getters.schoolConfigInfo;
    },
  },
  methods: {
    // 切换登录方式
    onChangeType() {
      this.type = this.type == 1 ? 2 : 1;
      if (this.type == 1) {
        console.log(123);
        setTimeout(() => {
          var obj = new WxLogin({
            self_redirect: false,
            id: "codeBox",
            appid: "wx047b3ed8010bfbde",
            scope: "snsapi_login",
            redirect_uri:
              process.env.VUE_APP_BASE_URL == "https://apitesttest.hrgcn.com/"
                ? "http%3A%2F%2Ftesttest.hrgcn.com%2F"
                : "http%3A%2F%2Fwww.hrgcn.com%2F",
            state: "3d6be0a40sssssxxxxx6624a415e",
            style: "",
            href: "",
          });
        }, 500);
      }
    },
    // 暂不登录
    onCancelLogin() {
      this.$store.dispatch("public/changeIsShowLoginModal", false).then((res) => {
        console.log("isCloseLoginModalSuccess");
      });
    },
    // 获取二维码链接
    onGetQRCodeUrl() {
      // console.log(this.loopCount);
      if (this.loopCount > 30) {
        clearInterval(this.qrcodeTimer);
        this.qrcodeTimer = null;
      }
      // 此处调用获取最新的二维码链接接口
    },
    // 获取验证码
    onGetSmsCode() {
      if (this.phone && validPhone(this.phone) && !this.getSmsCodeBtnLoading) {
        this.getSmsCodeBtnLoading = true;
        getLoginSmsCode({ phone: this.phone })
          .then((res) => {
            this.$message.success(this.$t("login.sendSmsCodeSuccessText"));
            this.isCanGetSmsCode = false;
            this.smsTimer = setInterval(() => {
              this.smsSeconds--;
              if (this.smsSeconds <= 0) {
                clearInterval(this.smsTimer);
                this.smsTimer = null;
                this.isCanGetSmsCode = true;
                this.smsSeconds = 60;
              }
            }, 1000);
          })
          .finally(() => (this.getSmsCodeBtnLoading = false));
      } else {
        this.$message.warning(this.$t("login.validatePhoneErrorText"));
      }
    },
    // 刷新验证码
    onUpdateImgCodeUrl() {},
    // 手机号输入框
    onPhoneChange(e) {
      if (e && validPhone(e)) {
        this.phoneValidate = true;
        this.onValidate();
      } else {
        this.phoneValidate = false;
        this.onValidate();
      }
    },
    // 图片验证码输入框
    onImgCodeChange(e) {
      if (e) {
        this.imgCodeValidate = true;
        this.onValidate();
      } else {
        this.imgCodeValidate = false;
        this.onValidate();
      }
    },
    // 短信验证码输入框
    onSmsCodeChange(e) {
      if (e && e.length == 6) {
        this.smsCodeValidate = true;
        this.onValidate();
      } else {
        this.smsCodeValidate = false;
        this.onValidate();
      }
    },
    onValidate() {
      return new Promise((resolve, reject) => {
        if (this.phoneValidate && this.imgCodeValidate && this.smsCodeValidate) {
          this.canLogin = true;
          resolve(true);
        } else {
          this.canLogin = false;
          resolve(false);
        }
      });
    },
    // 确认登录
    async onLogin() {
      const isOk = await this.onValidate();
      if (isOk) {
        this.loginBtnLoading = true;
        login({ phone: this.phone, smsCode: this.smsCode })
          .then((res) => {
            if (res.status == -1 && res.data.token) {
              sessionStorage.setItem("token", res.data.token);
              sessionStorage.setItem("userInfo", JSON.stringify(res.data.user.user));
              sessionStorage.setItem("schoolId", res.data.user.user.schoolId);
              sessionStorage.setItem("requestSchoolId", res.data.user.user.schoolId);
              this.$store.dispatch("public/changeIsShowLoginModal", false).then((res) => {
                console.log("isCloseLoginModalSuccess");
              });
              window.location.reload();
            }
          })
          .finally(() => (this.loginBtnLoading = false));
      }
    },
    // 扫码注册登录
    onValidateRegister() {
      return new Promise((resolve, reject) => {
        if (this.registerPhoneValidate && this.registerSmsCodeValidate) {
          this.canRegister = true;
          resolve(true);
        } else {
          this.canRegister = false;
          resolve(false);
        }
      });
    },
    // 手机号输入框
    onRegisterPhoneNumberInput(e) {
      if (e && validPhone(e)) {
        this.registerPhoneValidate = true;
        this.onValidateRegister();
      } else {
        this.registerPhoneValidate = false;
        this.onValidateRegister();
      }
    },
    // 短信验证码输入框
    onRegisterSmsCodeChange(e) {
      if (e && e.length == 6) {
        this.registerSmsCodeValidate = true;
        this.onValidateRegister();
      } else {
        this.registerSmsCodeValidate = false;
        this.onValidateRegister();
      }
    },
    // 获取验证码
    onGetRegisterSmsCode() {
      if (
        this.registerPhoneNumber &&
        validPhone(this.registerPhoneNumber) &&
        !this.getRegisterSmsCodeBtnLoading
      ) {
        this.getRegisterSmsCodeBtnLoading = true;
        getRegisterSmsCode({ phone: this.registerPhoneNumber })
          .then((res) => {
            this.isCanGetRegisterSmsCode = false;
            this.registerSmsTimer = setInterval(() => {
              this.registerSmsSeconds--;
              if (this.registerSmsSeconds <= 0) {
                clearInterval(this.registerSmsTimer);
                this.registerSmsTimer = null;
                this.isCanGetRegisterSmsCode = true;
                this.registerSmsSeconds = 60;
              }
            }, 1000);
          })
          .finally(() => (this.getRegisterSmsCodeBtnLoading = false));
      } else {
        this.$message.warning(this.$t("login.validatePhoneErrorText"));
      }
    },
    // 确认注册登录
    async onRegister() {
      const isOk = await this.onValidateRegister();
      if (isOk) {
        this.registerBtnLoading = true;
        createWxUserByScan({
          openid: this.openid,
          unionid: this.unionid,
          phone: this.registerPhoneNumber,
          smsCode: this.registerSmsCode,
        })
          .then((res) => {
            if (res.status == -1 && res.data.token) {
              sessionStorage.setItem("token", res.data.token);
              sessionStorage.setItem("userInfo", JSON.stringify(res.data.user.user));
              sessionStorage.setItem("schoolId", res.data.user.user.schoolId);
              sessionStorage.setItem("requestSchoolId", res.data.user.user.schoolId);
              this.$store.dispatch("public/changeIsShowLoginModal", false).then((res) => {
                console.log("isCloseLoginModalSuccess");
              });
              window.location.reload();
            }
          })
          .finally(() => {
            this.registerBtnLoading = false;
          });
      }
    },
    // 关闭弹窗
    handleCloseDialog(done) {},
    onCloseLoginModal() {
      this.$store.dispatch("public/changeIsShowLoginModal", false).then((res) => {
        console.log("isCloseLoginModalSuccess");
      });
    },
    onCloseRegisterModal() {
      this.registerDialog = false;
    },
    // 获取地址栏参数
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg); //匹配目标参数
      if (r != null) return decodeURIComponent(r[2]);
      return null; //返回参数值
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogBox {
  width: 100%;
  .dialogBox_title {
    width: 100%;
    padding: 18px;
    justify-content: flex-end;
    .dialogBox_close {
      cursor: pointer;
      .icon-close {
        padding: 0;
        color: #000;
        font-weight: 600;
      }
    }
  }
  .dialogBox_content {
    width: 100%;
    margin-top: 45px;
    padding: 0 60px;
    padding-bottom: 50px;
    .title1 {
      width: 100%;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
    .title2 {
      width: 100%;
      margin-top: 40px;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 30px;
    }
    .contentBox {
      width: 100%;
      margin-top: 20px;
      .codeBox {
        width: 164px;
        height: 164px;
        padding: 10px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #cccccc;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .contentBox_input {
      width: 100%;
      .inputBox {
        width: 100%;
        height: 36px;
        margin-top: 10px;
        input {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          border: 1px solid #e1e1e1;
        }
      }
      .inputBox_imgCode {
        .imgCodeInput {
          width: 50%;
        }
        img {
          width: 25%;
          height: 100%;
          margin-left: 10px;
          background: #e1e1e1;
          border-radius: 4px;
          cursor: pointer;
        }
        .updateImgCodeUrlBtn {
          width: 25%;
          margin-left: 8px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2b7bba;
          line-height: 20px;
          cursor: pointer;
        }
      }
      .error_tip {
        width: 100%;
        margin-top: 10px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a60002;
        line-height: 20px;
      }
      .loginBtn {
        width: 100%;
        height: 36px;
        margin-top: 10px;
        background: #a60002;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
      .loginBtnDisabled {
        background: #eeeeee;
        border-radius: 4px;
        border: 1px solid #e1e1e1;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #cccccc;
        cursor: no-drop;
      }
      ::-webkit-input-placeholder {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #cccccc;
      }
    }
    .bottom_btns {
      width: 100%;
      margin-top: 130px;
      .bottom_btn {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        cursor: pointer;
      }
      .bottom_btn_change {
        margin-right: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2b7bba;
        line-height: 20px;
      }
    }
  }
}
</style>
<style scoped>
#loginContainer >>> .el-dialog {
  border-radius: 12px;
}
#loginContainer >>> .el-dialog__body {
  padding: 0;
}
#loginContainer >>> .el-dialog__header {
  display: none;
}
#loginContainer >>> input::-webkit-outer-spin-button,
#loginContainer >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
#loginContainer >>> .el-dialog {
  min-width: 300px;
}
#loginContainer >>> .el-dialog__body {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
